import {
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  SwapHorizontalCircle,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { Drag } from "../../../../../components/DnD";

import IngredientInfo from "./IngredientInfo";
import DropDownProductsList from "./DropDownProductsList";
import { useConfirmModal } from "~/hooks";
import { deleteRecipeIngredient } from "../services";
import {
  NutritionInfo,
  Recipe,
  RecipeIngredient,
  Retailer,
} from "~/features/Recipes/types";
import { Lang, Toast } from "~/types";

type Props = {
  index: number;
  isDisabled: boolean;
  lang: Lang;
  nutritionInformations: NutritionInfo[];
  recipeIngredient: RecipeIngredient;
  retailers: Retailer[];
  recipe: Recipe;
  showMoreId?: string;
  showAllProducts: boolean;
  handleEdit: (id: string) => void;
  handleSwap: (id: string) => void;
  handleShowDropdown: (id: string) => void;
};

const IngredientRow = ({
  recipeIngredient,
  recipe,
  lang,
  index,
  nutritionInformations,
  retailers,
  showMoreId,
  showAllProducts,
  isDisabled,
  handleSwap,
  handleEdit,
  handleShowDropdown,
}: Props) => {
  const { id: ingredientId } = recipeIngredient.ingredient;

  const [showDeleteModal, DeleteModal] = useConfirmModal(
    deleteRecipeIngredient,
    [recipe.id, ingredientId],
    recipeIngredient?.ingredient?.title?.singular?.[lang] ?? "Ingredient"
  );

  return (
    <>
      <Drag key={ingredientId} id={ingredientId} index={index}>
        <div className="ml-8 flex grow items-center justify-between border border-neutral-300 border-dashed rounded-md py-2 px-3 shadow-sm">
          <div>
            <IngredientInfo
              index={index}
              recipeIngredient={recipeIngredient}
              lang={lang}
              nutritionInformations={nutritionInformations}
              handleEdit={handleEdit}
              id={ingredientId}
            />
            <DropDownProductsList
              recipeIngredient={recipeIngredient}
              retailers={retailers}
              lang={lang}
              showMoreId={showMoreId}
              index={index}
              showAllProducts={showAllProducts}
            />
          </div>
          <div className="flex items-start self-start gap-4">
            {isDisabled ? null : (
              <>
                <SwapIcon
                  recipeIngredient={recipeIngredient}
                  handleSwap={handleSwap}
                />
                <EditIcon id={ingredientId} handleEdit={handleEdit} />
                <DeleteIcon showDeleteModal={showDeleteModal} />
              </>
            )}
            <SubPanelIcon
              recipeIngredient={recipeIngredient}
              showMoreId={showMoreId}
              handleShowDropdown={handleShowDropdown}
              showAllProducts={showAllProducts}
              index={index}
            />
          </div>
        </div>
      </Drag>
      <DeleteModal />
    </>
  );
};

export default IngredientRow;

type SwapProps = {
  recipeIngredient: RecipeIngredient;
  handleSwap: (id: string) => void;
};

const SwapIcon = ({ recipeIngredient, handleSwap }: SwapProps) => (
  <div className="hover:text-primary-100">
    {recipeIngredient.changeable && (
      <SwapHorizontalCircle
        onClick={() => handleSwap(recipeIngredient.ingredient.id)}
        style={{
          fontSize: "24px",
          cursor: "pointer",
        }}
      />
    )}
    {!recipeIngredient.changeable && recipeIngredient.canBeRemoved && (
      <SwapHorizontalCircle style={{ color: "lightgray", fontSize: "24px" }} />
    )}
  </div>
);

type EditProps = {
  id: string;
  handleEdit: (id: string) => void;
};

const EditIcon = ({ id, handleEdit }: EditProps) => (
  <div className="hover:text-primary-100">
    <Edit
      onClick={() => handleEdit(id)}
      style={{
        fontSize: "24px",
        cursor: "pointer",
      }}
    />
  </div>
);

type DeleteProps = {
  showDeleteModal: () => void;
};

const DeleteIcon = ({ showDeleteModal }: DeleteProps) => (
  <div className="hover:text-primary-100">
    <Delete
      style={{
        fontSize: "24px",
        cursor: "pointer",
      }}
      onClick={() => showDeleteModal()}
    />
  </div>
);

type SubPanelIconProps = {
  recipeIngredient: RecipeIngredient;
  handleShowDropdown: (id: string) => void;
  showMoreId?: string;
  showAllProducts: boolean;
  index: number;
};

const SubPanelIcon = ({
  recipeIngredient,
  handleShowDropdown,
  showMoreId,
  showAllProducts,
  index,
}: SubPanelIconProps) => (
  <div className="hover:text-primary-100 cursor-pointer">
    {recipeIngredient.ingredient.products?.length !== 0 ? (
      <div
        onClick={() => {
          handleShowDropdown(index.toString());
        }}
      >
        {showMoreId === index.toString() || showAllProducts ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </div>
    ) : (
      <Icon
        style={{
          visibility: "hidden",
        }}
      />
    )}
  </div>
);
