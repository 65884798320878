import { Link } from "@remix-run/react";
import { ProductItem } from "~/features/Recipes/types";
import { Lang } from "~/types";

type Props = {
  productItem: ProductItem;
  lang: Lang;
};

const ProductListItem = ({ productItem, lang }: Props) => (
  <Link
    className="hover:text-primary-100"
    target="_blank"
    to={"/products/" + productItem.id}
  >
    <li
      key={productItem.id}
      style={productItem.ingredientBrand ? { background: "#E1E1D1" } : {}}
    >
      <span className="pl-2 pr-2">
        <span>{productItem?.retailerName}</span>
      </span>
      <span className="pr-2">{productItem.price}</span>
      <span className="pr-2">{productItem.quantity}</span>
      <span className="pr-2">{productItem.measure?.title?.[lang]}</span>
      <span>{productItem.title}</span>
    </li>
  </Link>
);

export default ProductListItem;
