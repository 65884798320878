import {
  ProductItem,
  RecipeIngredient,
  Retailer,
} from "~/features/Recipes/types";
import { Lang } from "~/types";
import ProductListItem from "./ProductListItem";
import { getIngProducts } from "../utils";

type Props = {
  recipeIngredient: RecipeIngredient;
  retailers: Retailer[];
  lang: Lang;
  showMoreId?: string;
  index: number;
  showAllProducts: boolean;
};

const DropDownProductsList = ({
  recipeIngredient,
  retailers,
  lang,
  showMoreId,
  index,
  showAllProducts,
}: Props) => {
  const { ingredient } = recipeIngredient;
  const productList = getIngProducts(ingredient.products, retailers);
  const showMore = showMoreId === index.toString() || showAllProducts;

  return (
    <div className={`more-info ${showMore ? "extended" : ""}`}>
      <ul className={showMore ? "flex flex-col pt-3 pb-2 pl-8" : "pl-8"}>
        {productList?.map((productItem: ProductItem) => (
          <ProductListItem key={productItem.id} productItem={productItem} lang={lang} />
        ))}
      </ul>
    </div>
  );
};

export default DropDownProductsList;
