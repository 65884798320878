import { Revalidator } from "~/types";
import { IngredientModalValues } from "./types";
import { patchData, postData } from "~/server/api_ts.client";
import { createToastMessage } from "~/server/utils";
import { DropResult } from "react-beautiful-dnd";

export const handleUpdateRecipeIngredient = async (
  formData: IngredientModalValues,
  revalidator: Revalidator
) => {
  const { recipeId, ingredient: ingredientId } = formData;

  const path = `/recipes/${recipeId}/ingredients/${ingredientId}`;
  const res = await patchData(path, formData, 200);
  const toastMessage = createToastMessage(
    res,
    `${formData.ingredientName} Updated`
  );

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleCreateRecipeIngredient = async (
  formData: IngredientModalValues,
  revalidator: Revalidator
) => {
  const { recipeId } = formData;

  const path = `/recipes/${recipeId}/ingredients`;
  const res = await postData(path, formData, 200);
  const toastMessage = createToastMessage(res, `Ingredient Added`);

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};

export const handleMoveIng = async (
  id: string,
  result: DropResult,
  revalidator: Revalidator
) => {
  const data = {
    current: result.source.index,
    update: result.destination?.index ?? 0,
  };

  const path = `/recipes/${id}/ingredients/order`;
  const res = await postData(path, data, 200);
  const toastMessage = createToastMessage(
    res,
    `Ingredient ${data.current + 1} Moved to Position ${data.update + 1}`
  );

  if (res.success) {
    revalidator.revalidate();
  }

  return toastMessage;
};
