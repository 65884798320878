import { useRevalidator } from "@remix-run/react";
import {
  NutritionInfo,
  Recipe,
  RecipeIngredient,
  Retailer } from
"~/features/Recipes/types";
import { Lang } from "~/types";
import { DropResult } from "react-beautiful-dnd";
import { getUpdatedItems } from "../utils";
import styles from "../../../DragTable.css";
import { DnDTable } from "../../../../../components/DnD";
import IngredientRow from "./IngredientRow";
import { Dispatch, SetStateAction } from "react";
import { handleMoveIng } from "../handlers";
import { ToastMessage } from "~/hooks/useToast";

type Props = {
  recipe: Recipe;
  recipeIngredients: RecipeIngredient[];
  retailers: Retailer[];
  lang: Lang;
  showMoreId?: string;
  showAllProducts: boolean;
  isDisabled: boolean;
  nutritionInformations: NutritionInfo[];
  setRecipeIngredients: Dispatch<SetStateAction<RecipeIngredient[]>>;
  handleEdit: (id: string) => void;
  handleSwap: (id: string) => void;
  setShowMoreId: Dispatch<SetStateAction<string | undefined>>;
  setToast: (toast: ToastMessage) => void;
};

const IngredientsDragTable = ({
  recipeIngredients,
  retailers,
  recipe,
  lang,
  showMoreId,
  showAllProducts,
  isDisabled,
  nutritionInformations,
  handleEdit,
  handleSwap,
  setShowMoreId,
  setRecipeIngredients,
  setToast
}: Props) => {
  const revalidator = useRevalidator();

  const handleDragEnd = async (result: DropResult) => {
    const updatedIngredients = getUpdatedItems(
      result,
      recipeIngredients,
      isDisabled
    );
    setRecipeIngredients(updatedIngredients);
    const toastMessage = await handleMoveIng(recipe.id, result, revalidator);
    setToast(toastMessage);
  };

  const handleShowDropdown = (id: string) => {
    setShowMoreId(showMoreId === id ? undefined : id);
  };

  return (
    <DnDTable className="draggable-table" handleDrag={handleDragEnd}>
      {recipeIngredients?.map(
        (recipeIngredient: RecipeIngredient, index: number) =>
        <IngredientRow
          key={recipeIngredient.ingredient.id}
          recipe={recipe}
          lang={lang}
          index={index}
          retailers={retailers}
          showMoreId={showMoreId}
          showAllProducts={showAllProducts}
          recipeIngredient={recipeIngredient}
          nutritionInformations={nutritionInformations}
          isDisabled={isDisabled}
          handleSwap={handleSwap}
          handleEdit={handleEdit}
          handleShowDropdown={handleShowDropdown} />


      )}
    </DnDTable>);

};

export const links = () => [
{
  rel: "stylesheet",
  href: styles
}];


export default IngredientsDragTable;